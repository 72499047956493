import { lazy } from 'react'

export interface Routes {
  path: string
  component: React.LazyExoticComponent<any>
  children?: Routes[]
}

// 布局页面
const LayoutPage = lazy(() => import('@/layout'))
// 404页面
const NotFoundPage = lazy(() => import('@/pages/404'))


export const RouteTable: Routes[] = [
  {
    path: '/',
    component: LayoutPage
  },
  {
    path: '/login',
    component: lazy(() => import('@/pages/login'))
  },
  {
    path: '/joe',
    component: LayoutPage,
    children: [
      {
        path: 'home',
        component: lazy(() => import('@/pages/home'))
      },
      {
        path: 'company',
        component: lazy(() => import('@/pages/company')),
      },
      {
        path: 'types',
        component: lazy(() => import('@/pages/types')),
      },
      {
        path: 'info',
        component: lazy(() => import('@/pages/info')),
      },
      {
        path: 'banner',
        component: lazy(() => import('@/pages/homeBanner')),
      },
      {
        path: 'user',
        component: lazy(() => import('@/pages/user')),
      }
    ]
  },
  {
    path: '*',
    component: NotFoundPage
  }
]