// router
import RouteTable from './router'
// react
import React, { useEffect, useState } from 'react'

const App = () => {
  return <RouteTable />
}

export default App
